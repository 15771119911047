<template>
  <div class="loader" v-if="taskState.isLoading==true"></div>
  <div class="boxed-layout">
    <NuxtLayout >
      <NuxtPage></NuxtPage>
    </NuxtLayout>
  </div>
</template>

<script setup>

let taskState = useTaskState();

// if (process.client){



// onBeforeRouteLeave((to, from) => {
//   if (process.client){
//     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
//     if (!answer) return false
//   }
// });

// }

// onBeforeUnmount(() => {
//   window.removeEventListener("beforeunload", confirmLeave);
// });

</script>

<style>
  @import '~/assets/sass/app.scss';
  @import '~/assets/sass/main.scss';
  
  .loader {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -50px; /* Half of height */
    margin-left: -50px; /* Half of width */
    z-index:10000
}
</style>

