const messages = ref(); // Moved outside the function
const agentMessages = ref(); // Moved outside the function
const voicemailMessages = ref(); // Moved outside the function
const missedCallsMessages = ref(); // Moved outside the function
const watcherMessages = ref(); // Moved outside the function
const userMessages = ref(); // Moved outside the function
const dashboardMessages = ref(); // Moved outside the function
const callcenterMessages = ref([]);


export const useWebSocket = () => {
  const { $webSocket } = useNuxtApp(); // Access the socket from the Nuxt plugin

  // Initialize and subscribe to user events
  const initWebSocket = (token, account_id) => {
    $webSocket.subscribeToEvents(token, account_id);
  };
  const unSubscribe = () => {
    $webSocket.unSubscribe();
  }

  return {
    agentMessages,
    watcherMessages,
    voicemailMessages,
    missedCallsMessages,
    userMessages,
    callcenterMessages,
    messages,
    initWebSocket,
    unSubscribe,
    dashboardMessages,
  };
};