


import libphonenumber from 'google-libphonenumber';

export default defineNuxtPlugin(() => ({
    
    provide: {
        toInternationalFormat: (n, country) => {
            const { PhoneNumberUtil, PhoneNumberFormat } = libphonenumber;
            const phoneUtil = PhoneNumberUtil.getInstance();
            if (n == "") return;
            const number = phoneUtil.parseAndKeepRawInput(n, country);
            return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
        },
        toNationalFormat: (n) => {
            const { PhoneNumberUtil, PhoneNumberFormat } = libphonenumber;
            const phoneUtil = PhoneNumberUtil.getInstance();
            if (n == "") return;
            try {
                const number = phoneUtil.parse(n); // Parse international format to get country information
                return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
            } catch (error) {
                // console.error('Error parsing phone number:', error, 'Number:', n);
                return n; // Return the original number if parsing fails
            }
        }
    },
}));