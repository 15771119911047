
export const useOutboundCDR = () => useState("outboundCDR", () => {
    return ref("")
});

export const useInboundCDR = () => useState("inboundCDR", () => {
    return ref("")
});


