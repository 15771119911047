export const useAgentPublicStatus = () => useState("agentPublicStatus", () => {
    return ref({});
});
export const useAgentPersonalStatus = () => useState("agentPersonalStatus", () => {
    return ref(null);
});

export const useAgentManagementMessages = () => useState("agentManagementMessages", () => {
    return ref({});
});

export const useSipAccountRegistrationStatus = () => useState("SipAccountRegistrationStatus", () => {
    return ref({});
});

export const useSipAccountCallState = () => useState("sipAccountCallState", () => {
    return ref({});
});