import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import bootstrap_client_zGQC9AE5yr from "/app/plugins/bootstrap.client.js";
import registerServiceWorker_client_vlRjy9WsvJ from "/app/plugins/registerServiceWorker.client.js";
import device_VEtfIepYun from "/app/plugins/device.js";
import i18n_sVHQBgnb3t from "/app/plugins/i18n.js";
import ivr_cRihdOaEw0 from "/app/plugins/ivr.js";
import maska_client_S2Wtj77gAt from "/app/plugins/maska.client.js";
import phonelib_client_pe3o5NglZ5 from "/app/plugins/phonelib.client.js";
import request_methods_GicjagKWG9 from "/app/plugins/request-methods.js";
import s3Uploader_client_ZTR9mFrq6i from "/app/plugins/s3Uploader.client.js";
import sweetalert2_yFjEFOaS8h from "/app/plugins/sweetalert2.js";
import utils_EeQrN1BEbD from "/app/plugins/utils.js";
import websocket_client_yuEk7YXzOs from "/app/plugins/websocket.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  bootstrap_client_zGQC9AE5yr,
  registerServiceWorker_client_vlRjy9WsvJ,
  device_VEtfIepYun,
  i18n_sVHQBgnb3t,
  ivr_cRihdOaEw0,
  maska_client_S2Wtj77gAt,
  phonelib_client_pe3o5NglZ5,
  request_methods_GicjagKWG9,
  s3Uploader_client_ZTR9mFrq6i,
  sweetalert2_yFjEFOaS8h,
  utils_EeQrN1BEbD,
  websocket_client_yuEk7YXzOs
]