export default defineNuxtPlugin((nuxtApp) => {
  // WebSocket server URL(s)
  const ws_url_arr = ['wss://es1.simplecallcenter.com:3000']; // Your WebSocket server URL
  // const ws_url_arr = ['wss://127.0.0.1:3000']; // Your WebSocket server URL

  const { agentMessages, watcherMessages, voicemailMessages, missedCallsMessages, userMessages, dashboardMessages } = useWebSocket();

  let company = useCompany();
  let dashboard = useDashboard();
  let agent_public_status = useAgentPublicStatus();
  let sipAccountRegistrationStatus = useSipAccountRegistrationStatus();
  let agent_personal_status = useAgentPersonalStatus();
  let agentEvents = useAgentManagementMessages();
  const hotlineCallCount = useHotlineCallCount();
  const hotlineCalls = useHotlineCalls();
  const sipAccountCallState = useSipAccountCallState();
  const agentCallState = useAgentCallState();
  const agentACDCallState = useAgentACDCallState();
  const outboundCDR = useOutboundCDR();
  const inboundCDR = useInboundCDR();

  // let callcenterData = useCallcenterData();
  let webSocket = null;
  let reconnectTimeout = null;
  const reconnectDelay = 3000; // 3 seconds delay before reconnecting

  // Store token and accountId so they can be used for reconnection
  let storedToken = null;
  let storedAccountId = null;

  // Initialize WebSocket connection
  function initSocketConnection(token, account_id) {
    storedToken = token;
    storedAccountId = account_id;
    if (!company.value.notification_server_id) {
      console.log('No MQTT server ID found. Cannot initialize WebSocket connection.');
      return;
    }
    let url = ws_url_arr[company.value.notification_server_id - 1];
    webSocket = new WebSocket(`${url}?token=${token}&accountId=${account_id}`);

    webSocket.onopen = () => {
      // Clear any scheduled reconnect attempts when connection is established
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    webSocket.onmessage = (event) => {

      const channel = JSON.parse(event.data).channel;
      const data = JSON.parse(JSON.parse(event.data).message);
      // console.log('Channel:', channel);
      console.log(data);

      // https://docs.google.com/document/d/1CGMN_UALcU-kM8CjUG8vnDJOxYLCIMjmzfdB9VTlBt8/edit?tab=t.0

      try {
        
        if (data.app === "voicemail" && (data.type == "voicemail" || data.type == "voicemail-state")) {
          voicemailMessages.value = data;
        }

        if (data.app === "missed-call" && (data.type == "missed-call" || data.type == "missed-call-state")) {
          console.log("Missed call event:", data);
          missedCallsMessages.value = data;
        }
        if (data.app === "call-count" && data.type == "outbound") {
          let callcenter_id = data.data.callcenter_id;
          if (callcenter_id){
            if (!(callcenter_id in dashboard.value)) {
              dashboard.value[callcenter_id] = {}
            }
            dashboard.value[callcenter_id].outbound_calls_count= data.data.currentOutboundCallsCount; 
          }
        }
        if (data.app === "call-state" && data.type == "inbound") {
          if (data.event=="caller-joined" || data.event=="caller-quit") {
              let callcenter_id = data.data.callcenter_id;
              if (callcenter_id){
                if (!(callcenter_id in dashboard.value)) {
                  dashboard.value[callcenter_id] = {}
                }
                dashboard.value[callcenter_id].inbound_calls_count= data.data.currentInboundCallsCount; 
              }
            }  
          // dashboardMessages.value = data;
          // missedCallsMessages.value = data;
        }
        if (data.app === "call-state" && data.event == "call-state-account") { //presence state from rtp freeswitch
          agentCallState.value[data.data.sip_account_id] = data.data;
        }

        if (data.app === "call-state" && data.event == "call-state-callcenter") { //presence state from rtp freeswitch
          agentACDCallState.value[data.data.sip_account_id] = data.data;
        }

        if (data.app === "call-state" &&  data.event == "call-state-user") {
          
          sipAccountCallState.value = data.data;
        }

        if (data.app =="agent-status" && data.type == "public") { // public - means for all wathchers in account
          agent_public_status.value[data.data.agent_id] = data.data.status;
        }
        if (data.app =="agent-status" && data.type == "personal") { // personal - only for agent, that owns this status
          agent_personal_status.value = data.data.status;
        }

        if (data.app === 'callcenter-user-management' && (data.event === 'agent-added' || data.event === 'agent-updated' || data.event === 'agent-deleted')) {
          // New agent/supervisor was added to callcenter
          // this is triggered by record insert/update/delete in callcenter-user collection
          // since agent can be a part of seeral callcenter, this updated per individual callcenter. 
          // on agent management page, we need to update type and hotline, or delete entry or add entry per callcenter_id
          // console.log("Agent management event:", data);
          agentEvents.value = data;
        }

        if (data.app === 'sip-account-management' && (data.event === 'agent-updated')) {
          // When sip account is updated:
          //  - we update all watchers to tell them that user joined the platform and 
          // - updating parameters of agent (sip related like intervals, timeout, etc)
          agentEvents.value = data;
        }

        if (data.app === 'session-event' && data.event === 'agent-deleted') {
          // kick out agent from the platform
          userMessages.value = data;
        }

        if (data.app === 'hotlines' && data.event === 'members-count') {
          if (!(data.data.callcenter_id in hotlineCallCount.value)) {
            hotlineCallCount.value[data.data.callcenter_id] = {};
          }
          
          if (!hotlineCallCount.value[data.data.callcenter_id].hasOwnProperty(data.data.hotline_id)) {
            hotlineCallCount.value[data.data.callcenter_id][data.data.hotline_id] = 0;
          }
          hotlineCallCount.value[data.data.callcenter_id][data.data.hotline_id] = data.data.count;
        }

        if (data.app === 'bridge-agent'){
          hotlineCalls.value = data.data;
          hotlineCalls.value.event = data.event;
        }

        if (data.app === 'sip-status') {
            sipAccountRegistrationStatus.value[data.data.sip_account_id] = data.event;
        }
        if (data.app === 'cdr') {
          if (data.type == "outbound"){
            outboundCDR.value = data.data.uuid;
            // alert(JSON.stringify(data.data));
          }
          if (data.type == "inbound"){
            inboundCDR.value = data.data.uuid;
          }
        }



        // const data = JSON.parse(event.data);
        if (/^agent_(.*)/.test(channel)) {
          agentMessages.value = data;
        }
       
        if (data.type === 'watchers') {
          watcherMessages.value = data;
        }
        
        
        

        // if (data.app === 'inbound-call') {
        //   if (data.event=="caller-joined" || data.event=="caller-quit") {
        //     let callcenter_id = data.data.callcenter_id;
        //     if (callcenter_id){
        //       if (!(callcenter_id in dashboard.value)) {
        //         dashboard.value[callcenter_id] = {}
        //       }
        //       dashboard.value[callcenter_id].inbound_calls_count= data.data.currentInboundCallsCount; 
        //     }
        //   }
        // }

        if (data.app === 'balance') {
          let callcenter_id = data.data.callcenter_id;
          if (callcenter_id){
            if (!(callcenter_id in dashboard.value)) {
              dashboard.value[callcenter_id] = {}
            }
            dashboard.value[callcenter_id].monthly_balance= data.data.monthly_balance; 
            dashboard.value[callcenter_id].topup_balance = data.data.topup_balance;
          }
          // dashboardMessages.value = data;
        }
        // if (data.app === 'new-call' && data.event == 'cdr-balance') {
        //   dashboardMessages.value = data;
        // }


      } catch (error) {
        console.log('Error parsing message:', error);
      }
    };

    webSocket.onclose = (event) => {
      console.log('WebSocket disconnected:', event.code, event.reason);
      // Attempt to reconnect after a delay
      reconnectTimeout = setTimeout(() => {
        console.log('Reconnecting WebSocket...');
        initSocketConnection(storedToken, storedAccountId);
      }, reconnectDelay);
    };

    webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      // Optionally, close the socket if there's an error to trigger the reconnect logic
      webSocket.close();
    };
  }

  // Subscribe to user events (initial connection)
  function subscribeToEvents(token, account_id) {
    initSocketConnection(token, account_id);
  }

  function unSubscribe() {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(JSON.stringify({ action: 'unsubscribe' }));
    }
  }

  // Process incoming data (if needed)
  function processData(data) {
    try {
      const object = JSON.parse(data);
      console.log('Processed data:', object);
    } catch (error) {
      console.log('Error processing data:', error);
    }
  }

  // Provide the socket and methods globally
  nuxtApp.provide('webSocket', {
    webSocket,
    initSocketConnection,
    subscribeToEvents,
    unSubscribe,
    processData,
  });
});
